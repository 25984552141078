import React from "react";
import DataTable from "react-data-table-component";
import { useSession } from "../../context/SessionContext";

const PedidoView = ({ ordenes }) => {
    const { user } = useSession();
    const agrupadas = Object.entries(ordenes).map(([id_cocina, productos]) => {
        return {
            id_cocina,
            productos,     
        };
    });

    const columns = [
        {name:"ID", selector: row => row.id },
        {name:"Nombre", selector: row => row.nombre },
        user?.tipo === "Bar" ? {name:"Precio", selector: row => ` $ ${row.precio} mx`} : {name:"Precio venta", selector: row => ` $ ${row.precio_compra} mx`},
        {name:"Cantidad", selector: row => row.quantity },
        user?.tipo === "Bar" ? {name:"En este pedido", selector: row => ` $ ${row.quantity * row.precio} mx` } : {name:"En este pedido", selector: row => ` $ ${row.quantity * row.precio_compra} mx` },
    ]

    return(
        <div className="space-y-4">
            {agrupadas?.map(({id_cocina, productos })=>(
                <div key={id_cocina} className="border-2 border-violet-300 rounded-lg">
                    <DataTable
                        data={productos}
                        columns={columns}
                    />
                </div>
            ))}
        </div>
    )
}

export default PedidoView;