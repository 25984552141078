import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import EncuestaField from "../atoms/EncuestaField";
import ButtonForm from "../atoms/ButtonForm";
import { toast } from "react-toastify";
import Logo from "../assets/Imagenes/neon.webp"
import { CREATE_POLL } from "../../apiAdmin";
import Fondo from "../assets/Imagenes/FondoMenu.jpg"

const Encuesta = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Encuesta de satisfacción - Contenedor";
    }, []);

    const handleSubmit = async(values, {resetForm}) => {
        try{
            setLoading(true);
            const encuestaFormateada = {
                "¿Que le parecio el ambiente y la decoracion del lugar?": values.ambiente,
                "¿Como le parecio el servicio y atencion que le brindo nuestro personal?": values.servicio,
                "¿Como considera el tiempo de espera en el servicio?": values.tiempo,
                "¿Que piensa acerca de la presentacion de los platillos?": values.presentacion,
                "¿Como calificaria la relacion calidad-precio de los alimentos que le sirvieron?": values.worth,
                "¿Como le parecio nuestro menu de comida?": values.menu,
                "¿Como califica la limpieza de nuestro restaurante?": values.limpieza,
                "¿Que posibilidades hay de que recomiende nuestro restaurante a un amigo?": values.recomendacion,
                "Por favor, mencione lo que mas le gusto y lo que menos le gusto de nuestro menu (comida y bebida)": values.liked,
                "Comentarios y/o sugerencias:": values.comments,
            };
            const payload = { 
                encuesta: encuestaFormateada
            };
            await CREATE_POLL(payload);
            toast.success("Enviado exitosamente, gracias");
            resetForm();
        }catch (error) {
            console.error(error);
            toast.error("Error al enviar");
        } finally {
            setLoading(false);
        }
    }

    const initialValues = {
        ambiente: "", servicio:"", tiempo:"", presentacion:"", worth:"", 
        menu:"", limpieza:"", recomendacion:"", liked: "", comments:"",
    } 

    const validate = (values) => {
        const errors = {};
        const requiredFields = [
            "ambiente", "servicio", "tiempo", 
            "presentacion", "worth", "menu", 
            "limpieza", "recomendacion"
        ];
        
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = "Este campo es obligatorio";
            }
        });
        return errors;
    };    

    return (
        <div 
            className="w-full h-full min-h-screen flex items-center justify-center bg-cover bg-center"
            style={{ backgroundImage: `url(${Fondo})` }}
        >
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
        >
            <Form className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4">
                <div className="w-full flex justify-center items-center">
                    <img src={Logo} className="w-1/3" />
                </div>
                <EncuestaField 
                    name="ambiente" 
                    label="¿Qué le pareció el ambiente y la decoración del lugar?"
                    type="radio"                 />
                <EncuestaField 
                    name="servicio" 
                    label="¿Cómo le pareció el servicio y atención que le brindó nuestro personal?"
                    type="radio" 
                />
                <EncuestaField 
                    name="tiempo" 
                    label="¿Cómo considera el tiempo de espera en el servicio?"
                    type="radio" 
                />
                <EncuestaField 
                    name="presentacion" 
                    label="¿Qué piensa acerca de la presentación de los platillos?"
                    type="radio" 
                />
                <EncuestaField 
                    name="worth" 
                    label="¿Cómo calificaría la relación calidad-precio de los alimentos que le sirvieron?"
                    type="radio" 
                />
                <EncuestaField 
                    name="menu" 
                    label="¿Cómo le pareció nuestro menú de comida?"
                    type="radio" 
                />
                <EncuestaField 
                    name="limpieza" 
                    label="¿Cómo califica la limpieza de nuestro restaurante?"
                    type="radio" 
                />
                <EncuestaField 
                    name="recomendacion" 
                    label="¿Qué posibilidades hay de que recomiende nuestro restaurante a un amigo?"
                    type="radio" 
                />
                <EncuestaField 
                    name="liked" 
                    label="Por favor, mencione lo que más le gustó y lo que menos le gustó de nuestro menú (comida y bebida)" 
                />
                <EncuestaField 
                    name="comments" 
                    label="Comentarios y/o sugerencias:" 
                />
                <div className="flex items-center justify-center">
                    <ButtonForm type="submit" text={loading ? "Enviando" : "Enviar"} bgColor={loading ? "bg-gray-300" : "bg-green-700"} width="w-32"/>
                </div>
            </Form>
        </Formik>
        </div>
    );
};

export default Encuesta;
