import React, { useState } from "react";
import DataTable from "react-data-table-component";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import LargeText from "../atoms/LargeText";
import { SiMicrosoftexcel } from "react-icons/si";
import PedidoBar from "./bar/PedidoBar";
import NoData from "../atoms/NoData";
import { GET_CONSUMABLES } from "../../apiAdmin";
import * as XLSX from "xlsx";

const TableBD = ({ data, kitchenId, kitchenType }) => {
	const [orden, setOrden] = useState(null);
	const { openModal, closeModal, isOpen } = useModal();
	const [isLoading, setIsLoading] = useState(false);

	const calculateTotalProducts = (orden) => {
		return orden.reduce((total, item) => total + item.totalProduct, 0);
	};

	const calculateTotalGanancia = () => {
		return data.reduce(
			(total, row) => total + calculateTotalProducts(row.orden[kitchenId]),
			0
		);
	};

	const handleOpen = (orden) => {
		const ordenFiltrada = orden[kitchenId] || {};
		openModal();
		setOrden(ordenFiltrada);
	};

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
		},
		{
			name: "Pago",
			selector: (row) => row.metodo_pago,
		},
		{
			name: "Total",
			selector: (row) => `$${calculateTotalProducts(row.orden[kitchenId])} MX`,
		},
		{
			name: "Acciones",
			selector: (row) => (
				<ButtonForm
					text="Ver productos"
					bgColor="bg-orange-500"
					width="w-28"
					onClick={() => handleOpen(row.orden)}
				/>
			),
		},
	];

	const convertArrayOfObjectsToCSV = (array) => {
		const columnDelimiter = ",";
		const lineDelimiter = "\n";
		const keys = Object.keys(array[0]);

		let result = "";
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		array.forEach((item) => {
			const row = keys.map((key) => item[key]).join(columnDelimiter);
			result += row + lineDelimiter;
		});

		return result;
	};

	const downloadCSV = (array) => {
		const csvData = convertArrayOfObjectsToCSV(array);
		const link = document.createElement("a");
		const filename = "reporteContenedor.csv";

		let csv;

		if (!csvData.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csvData}`;
		} else {
			csv = csvData;
		}

		link.setAttribute("href", encodeURI(csv));
		link.setAttribute("download", filename);
		link.click();
	};

	const handleExport = () => {
		const exportData = data.map((row) => ({
			id: row.id,
			metodo_pago: row.metodo_pago,
			total: calculateTotalProducts(row.orden[kitchenId]),
		}));

		exportData.push({
			id: "Total",
			metodo_pago: "",
		});

		downloadCSV(exportData);
	};

	const handleExportSales = async () => {
		setIsLoading(true);
		try {
			const consumablesData = await GET_CONSUMABLES();

			// Mapeo de productos con su nombre y medidas
			const consumablesMap = consumablesData.reduce((acc, item) => {
				acc[item.id] = {
					nombre: item.almacen.nombre,
					medidas: item.medidas || 1,
				};
				return acc;
			}, {});

			const salesSummary = {};

			data.forEach((order) => {
				if (!order.orden[kitchenId]) return;

				order.orden[kitchenId].forEach((product) => {
					product.receta.forEach((recetaItem) => {
						const consumableId = recetaItem.id;
						const consumableData = consumablesMap[consumableId] || {
							nombre: "Desconocido",
							medidas: 1,
						};
						const medidasPorBotella = consumableData.medidas;
						const quantity = product.quantity;
						const price = parseFloat(product.precio);

						// Calcular cuántas botellas se vendieron
						const botellasVendidas = Math.floor(quantity / medidasPorBotella);
						const unidadesRestantes = quantity % medidasPorBotella;

						// Si las unidades restantes superan las medidas, se cuenta como una botella adicional
						if (unidadesRestantes >= medidasPorBotella) {
							botellasVendidas += 1;
							unidadesRestantes -= medidasPorBotella;
						}

						// Precio total de las botellas vendidas
						const ventasPorBotellasEnPesos =
							botellasVendidas * medidasPorBotella * price;

						// Ajustar ventas por copeo
						const ventasPorCopeoEnUnidad = unidadesRestantes;
						const ventasPorCopeoEnPesos = unidadesRestantes * price;

						if (salesSummary[consumableId]) {
							// Se suman los valores
							salesSummary[consumableId].ventasPorCopeoEnUnidad +=
								ventasPorCopeoEnUnidad;
							salesSummary[consumableId].ventasPorBotella += botellasVendidas;
							salesSummary[consumableId].ventasPorCopeoEnPesos +=
								ventasPorCopeoEnPesos;
							salesSummary[consumableId].ventasPorBotellasEnPesos +=
								ventasPorBotellasEnPesos;
						} else {
							// Se crea un nuevo objeto con los valores iniciales
							salesSummary[consumableId] = {
								consumableId,
								nombreProducto: consumableData.nombre,
								ventasPorCopeoEnUnidad,
								ventasPorBotella: botellasVendidas,
								ventasPorCopeoEnPesos: ventasPorCopeoEnPesos,
								ventasPorBotellasEnPesos: ventasPorBotellasEnPesos,
							};
						}
					});
				});
			});

			const salesData = Object.values(salesSummary).map((item) => ({
				...item,
				ventasPorCopeoEnPesos: ` ${item.ventasPorCopeoEnPesos.toFixed(2)}`,
				ventasPorBotellasEnPesos: ` ${item.ventasPorBotellasEnPesos.toFixed(
					2
				)}`,
			}));

			if (salesData.length === 0) {
				alert("No hay datos para exportar");
				return;
			}

			const ws = XLSX.utils.json_to_sheet(salesData);

			const headers = [
				"ID producto",
				"Nombre del producto",
				"Ventas por copeo en unidad",
				"Ventas por Botella",
				"Ventas por copeo en pesos",
				"Ventas por Botellas en pesos",
			];
			XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Ventas");

			XLSX.writeFile(wb, "Ventas Bar.xlsx");
		} catch (error) {
			console.error("Error exportando ventas:", error);
			alert("Hubo un error al exportar los datos");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-white rounded-lg shadow-lg">
			<div className="flex gap-4 justify-end">
				{kitchenType === "Bar" && (
					<ButtonForm
						onClick={handleExportSales}
						bgColor={isLoading ? "bg-gray-500" : "bg-green-700"}
						width="w-50"
						text={isLoading ? "Cargando..." : `Exportar Ventas`}
						icon={<SiMicrosoftexcel size={30} />}
						disabled={isLoading}
					/>
				)}
				<ButtonForm
					onClick={handleExport}
					bgColor="bg-green-700"
					width="w-32 "
					text={`Exportar`}
					icon={<SiMicrosoftexcel size={30} />}
				/>
			</div>
			<DataTable
				data={data}
				columns={columns}
				fixedHeader
				fixedHeaderScrollHeight="63vh"
				pagination
				paginationPerPage={10}
				noDataComponent={<NoData />}
				paginationRowsPerPageOptions={[10, 20, 50, 100]}
			/>
			<LargeText
				colorText="ml-2"
				text={`Total ganancia general: $${calculateTotalGanancia()} MX`}
				textBold
			/>
			{isOpen && (
				<ModalAdmin title="Orden" onClose={closeModal}>
					<PedidoBar ordenes={[orden]} />
				</ModalAdmin>
			)}
		</div>
	);
};

export default TableBD;
