import React, { useState, useEffect, useCallback } from "react";
import { GET_BITACORA, GET_CONSUMABLES } from "../../../apiAdmin";
import DataTable from "react-data-table-component";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const IssuesTable = ({ kitchenId }) => {
    const [issues, setIssues] = useState([]);
    const [consumables, setConsumables] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const data = await GET_BITACORA();
            const filteredData = data.filter(issue => issue.id_cocina === parseInt(kitchenId, 10));
            setIssues(filteredData);
            const dataConsumables = await GET_CONSUMABLES();
            const filteredConsumables = dataConsumables.filter(consumable => consumable.almacen.id_cocina === parseInt(kitchenId, 10));
            setConsumables(filteredConsumables);
        } catch (error) {
            console.error("Error al cargar la bitácora", error);
        }
    }, [kitchenId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getConsumableName = (id) => {
        const consumable = consumables.find(c => c.id === id);
        return consumable ? consumable.almacen.nombre : "Desconocido";
    };

    const columns = [
        { name: "ID", selector: row => row.id, width: "45px" },
        { name: "Copas o unidades", selector: row => row.cantidad, width: "140px" },
        { name: "Producto", selector: row => getConsumableName(row.id_almacen), width: "15vw" },
        { name: "Razón", selector: row => row.razon, width: "33vw" },
        { name: "Fecha", selector: row => moment(row.createdAt).format("DD/MM/YYYY HH:mm"), sortable: true },
    ];

    const exportToExcel = () => {
        const formattedData = issues.map(issue => ({
            ID: issue.id,
            "Copas o unidades": issue.cantidad,
            Producto: getConsumableName(issue.id_almacen),
            Razon: issue.razon,
            Fecha: moment(issue.createdAt).format("DD/MM/YYYY HH:mm"),
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Bitácora");

        worksheet["!cols"] = [
            { wch: 10 }, 
            { wch: 15 },
            { wch: 25 }, 
            { wch: 30 }, 
            { wch: 20 }, 
        ];

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

        saveAs(data, `Bitacora_${moment().format("DD/MM/YY")}.xlsx`);
    };

    return (
        <div>
            <button onClick={exportToExcel} className="p-2 bg-green-700 text-white rounded-md mb-2">
                Exportar a Excel
            </button>
            <DataTable data={issues} columns={columns} />
        </div>
    );
};

export default IssuesTable;
