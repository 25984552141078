import { Field, ErrorMessage } from "formik";
import { FaRegFrown, FaRegGrin, FaRegMeh, FaRegSmile, FaSadTear } from "react-icons/fa";

const EncuestaField = ({ name, label, type = "text", ...rest }) => {
    const options = [
        { value: "1", label: "Muy insatisfecho", icon: <FaSadTear />, color: "text-red-500" },
        { value: "2", label: "Insatisfecho", icon: <FaRegFrown />, color: "text-red-300" },
        { value: "3", label: "Neutral", icon: <FaRegMeh />, color: "text-yellow-300" },
        { value: "4", label: "Satisfecho", icon: <FaRegSmile />, color: "text-orange-500" },
        { value: "5", label: "Muy satisfecho", icon: <FaRegGrin />, color: "text-green-500" },
    ];

    return (
        <div className="w-full relative">
            <label htmlFor={name} className="text-yellow-300 text-lg font-bold">
                {label}
            </label>
            <br />
            
            {type === "radio" ? (
                <div className="flex justify-between items-center mt-3">
                    <Field name={name} {...rest}>
                        {({ field }) => (
                            <div className="flex gap-4">
                                {options.map((option) => (
                                    <label
                                        key={option.value}
                                        className="cursor-pointer flex flex-col items-center"
                                        htmlFor={`${name}_${option.value}`}
                                    >
                                        <input
                                            type="radio"
                                            id={`${name}_${option.value}`}
                                            name={name}
                                            value={option.value}
                                            className="hidden"
                                            checked={field.value === option.value}
                                            onChange={field.onChange}
                                        />
                                        <div
                                            className={`text-4xl ${
                                                field.value === option.value ? option.color : "text-gray-500"
                                            }`}
                                        >
                                            {option.icon}
                                        </div>
                                    </label>
                                ))}
                            </div>
                        )}
                    </Field>
                </div>
            ) : (
                <Field
                    type={type}
                    name={name}
                    id={name}
                    className="mt-2 w-full p-2 border border-yellow-300 focus:border-green-600 rounded-lg text-yellow-300"
                    {...rest}
                />
            )}

            <ErrorMessage name={name}>
                {(message) => <div className="absolute right-0 text-sm text-yellow-300 text-bold">{message}</div>}
            </ErrorMessage>
        </div>
    );
};

export default EncuestaField;
