import React, { useState, useEffect } from "react";
import { getKitchen } from "../../apiTheLaundry/api";
import { GET_ADMINS, GET_CANCELLATIONS, GET_WAITERS } from "../../apiTheLaundry/apiAdmin";
import DataTable from "react-data-table-component";
import moment from "moment/moment";

const HistorialDeCancelacionesTL = () => {
    const [cocinas, setCocinas] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [meseros, setMeseros] = useState([]);
    const [selectedKitchen, setSelectedKitchen] = useState("");
    const [selectedWaiter, setSelectedWaiter] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [cancellations, setCancellations] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setAdmins(await GET_ADMINS());
                setCocinas(await getKitchen());
                setMeseros(await GET_WAITERS());
                setCancellations(await GET_CANCELLATIONS());
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const getWaiterName = (idMesero) => meseros.find(m => m.id === idMesero)?.user.username || "Desconocido";
    const getKitchenName = (idCocina) => cocinas.find(c => c.id === idCocina)?.nombre || "Desconocido";
    const getAdminName = (idAdmin) => admins.find(a => a.id === idAdmin)?.nombre || "Desconocido";

    const filteredCancellations = cancellations.filter(({ id_cocina, id_mesero, createdAt }) => {
        const matchesKitchen = selectedKitchen ? id_cocina === parseInt(selectedKitchen) : true;
        const matchesWaiter = selectedWaiter ? id_mesero === parseInt(selectedWaiter) : true;
        const matchesDate = startDate && endDate ?
            moment(createdAt).isBetween(startDate, endDate, 'day', '[]') : true;
        return matchesKitchen && matchesWaiter && matchesDate;
    });

    return (
        <div className="border-2 border-black p-4 rounded-lg shadow-lg">
            <div className="mb-4 flex flex-wrap gap-4">
                <div>
                    <label className="mr-2 font-bold">Filtrar por cocina:</label>
                    <select className="border rounded py-2 px-4 border-violet-300" value={selectedKitchen} onChange={(e) => setSelectedKitchen(e.target.value)}>
                        <option value="">Todas</option>
                        {cocinas.map(({ id, nombre }) => <option key={id} value={id}>{nombre}</option>)}
                    </select>
                </div>
                <div>
                    <label className="mr-2 font-bold">Filtrar por mesero:</label>
                    <select className="border rounded py-2 px-4 border-violet-300" value={selectedWaiter} onChange={(e) => setSelectedWaiter(e.target.value)}>
                        <option value="">Todos</option>
                        {meseros.map(({ id, user }) => <option key={id} value={id}>{user.username}</option>)}
                    </select>
                </div>
                <div>
                    <label className="mr-2 font-bold">Desde:</label>
                    <input type="date" className="border rounded py-2 px-4 border-violet-300" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div>
                    <label className="mr-2 font-bold">Hasta:</label>
                    <input type="date" className="border rounded py-2 px-4 border-violet-300" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </div>
            <DataTable data={filteredCancellations} columns={[
                { name: "ID", selector: row => row.id },
                { name: "Razon", selector: row => row.razon },
                { name: "Producto", selector: row => row.producto },
                { name: "Precio", selector: row => `$${row.precio}MX` },
                { name: "Cocina", selector: row => getKitchenName(row.id_cocina) },
                { name: "Admin", selector: row => getAdminName(row.id_admin) },
                { name: "Mesero", selector: row => getWaiterName(row.id_mesero) },
                { name: "Fecha", selector: row => moment(row.createdAt).format("DD/MM/YY HH:mm") },
            ]} />
        </div>
    );
};

export default HistorialDeCancelacionesTL;
