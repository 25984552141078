import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import useModal from "../Hooks/useModal";
import Circle from "../atoms/Circle";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ListProducts from "../adminMolecules/ListProducts";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import FilterKD from "../adminOrganisms/FilterKD";
import EditKitchen from "../adminOrganisms/EditKitchen";
import { getKitchenById } from "../../api";
import { GET_HISTORIAL } from "../../apiCaja";
import { DELETE_KITCHEN } from "../../apiAdmin";
import TableBD from "../adminOrganisms/TableBD";
import TableCorte from "../adminOrganisms/bar/TableCorte";
import IssuesTable from "../adminOrganisms/bar/IssuesTable";
import H2Custom from "../atoms/H2Custom";

const BarDetail = () => {
	const navigate = useNavigate();
	const { kitchenId } = useParams();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(null);
	const [kitchen, setKitchen] = useState(null);
	const [dataOrders, setDataOrders] = useState([]);
	const [originalOrders, setOriginalOrders] = useState([]);
	const { openModal, closeModal, isOpen } = useModal();
	const {
		openModal: openEdit,
		closeModal: closeEdit,
		isOpen: isOpenEdit,
	} = useModal();
	const {
		openModal: openDetails,
		closeModal: closeDetails,
		isOpen: isOpenDetails,
	} = useModal();

	const today = moment().format("YYYY-MM-DD");

	const fetchKitchen = useCallback(async () => {
		try {
			setLoading(true);
			setError(null);
			const data = await getKitchenById(kitchenId);
			setKitchen(data);
		} catch (error) {
			setKitchen(null);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	}, [kitchenId]);

	useEffect(() => {
		fetchKitchen();
	}, [fetchKitchen]);

	useEffect(() => {
		const getDataOrders = async () => {
			try {
				const ordersData = await GET_HISTORIAL();
				const ordersFiltered = ordersData.filter(
					(order) => order?.orden[kitchenId]
				);
				setOriginalOrders(ordersFiltered);
				setDataOrders(
					ordersFiltered.filter((order) => {
						const orderDate = moment(order.createdAt);
						const start = moment(today).startOf("day");
						const end = moment(today).endOf("day");
						return orderDate.isBetween(start, end, null, "[]");
					})
				);
			} catch (err) {
				console.error(err);
			}
		};
		getDataOrders();
	}, [kitchenId, today]);

	useEffect(() => {
		document.title = "Bar - Contenedor";
	}, []);

	const handleDelete = async () => {
		try {
			const status = await DELETE_KITCHEN(kitchen?.id_user);
			if (status === 200) {
				toast.success("Cocina eliminada correctamente");
				navigate(`/kitchens`);
			} else {
				toast.error("Error al eliminar la cocina");
			}
		} catch (error) {
			console.error("detail", error);
			toast.error("Error al eliminar la cocina");
		}
	};

	if (loading) return <div>CARGANDO...</div>;
	if (error)
		return (
			<div className="w-full h-full">
				<div className="w-full text-center py-4">
					<LargeText
						text={error}
						colorText="text-red-500"
						textBold
						size="text-4xl"
					/>
				</div>
			</div>
		);

	const handleGo = (id) => {
		navigate(`/barAdmin/menu/${id}`);
	};

	return (
		<main className="w-full h-full flex flex-col space-y-4">
			<div className="w-full h-auto flex flex-wrap sm:flex-row items-center space-x-4 border-2 border-gray-200 bg-slate-200 rounded-lg px-2">
				<Circle
					cocina={kitchen}
					Width="w-[13%] aspect-square"
					selected
					Height=""
				/>
				<H2Custom
					text={`Cocina: ${kitchen?.nombre}`}
					textBold
					size="text-2xl"
					textAling="text-center"
					colorText=""
					style={{ color: kitchen?.text_color }}
				/>
				<div className="flex flex-col w-[23%]">
					<ButtonForm
						text="Ver el menú"
						bgColor="bg-green-600"
						onClick={() => handleGo(kitchenId)}
					/>
					<ButtonForm
						text="Editar bar"
						bgColor="bg-blue-500"
						onClick={openEdit}
					/>
				</div>
				<div className="flex flex-col w-[23%]">
					<ButtonForm
						text="Detalles en inventario"
						bgColor="bg-yellow-400"
						onClick={openDetails}
					/>
					<ButtonForm
						text="Eliminar bar"
						bgColor="bg-red-500"
						onClick={openModal}
					/>
				</div>
			</div>
			<div className="w-full h-auto flex flex-col space-y-4 p-4 bg-slate-200 rounded-lg">
				<FilterKD
					originalOrders={originalOrders}
					setDataOrders={setDataOrders}
					today={today}
				/>
				<TableBD
					data={dataOrders}
					kitchenId={kitchenId}
					kitchenType={kitchen?.tipo}
				/>
			</div>
			<div className="w-full h-auto flex flex-col space-y-4 p-4 bg-slate-200 rounded-lg">
				<TableCorte kitchenId={kitchenId} />
			</div>
			<ListProducts kitchenId={kitchenId} dataOrders={dataOrders} />
			{isOpen && (
				<ModalConfirmAdmin onClose={closeModal} onConfirm={handleDelete} />
			)}
			{isOpenEdit && (
				<ModalAdmin title="Editar cocina" onClose={closeEdit}>
					<EditKitchen
						onClose={closeEdit}
						cocina={kitchen}
						refetch={fetchKitchen}
					/>
				</ModalAdmin>
			)}
			{isOpenDetails && (
				<ModalAdmin title="Ajustes en inventario" onClose={closeDetails}>
					<IssuesTable kitchenId={kitchenId} />
				</ModalAdmin>
			)}
		</main>
	);
};

export default BarDetail;
