import React, { useCallback, useEffect, useState } from "react";
import { GET_POLL } from "../../apiAdmin";
import DataTable from "react-data-table-component";
import ButtonForm from "../atoms/ButtonForm";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSession } from "../../context/SessionContext";
import LargeText from "../atoms/LargeText";

const ControlDeCalidad = () => {
    const { user } = useSession();
    const [encuestas, setEncuestas] = useState([]);
    const [loading, setLoading] = useState(false);

    const convertirCalificacion = (valor) => {
        const calificaciones = {
            "5": "Excelente",
            "4": "Muy bueno",
            "3": "Regular",
            "2": "Malo",
            "1": "Muy malo"
        };
        return calificaciones[valor] || valor;
    };

    const fetchPoll = useCallback(async () => {
        try {
            setLoading(true);
            const data = await GET_POLL();
            setEncuestas(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPoll();
        document.title = "Encuestas - Contenedor";
    }, [fetchPoll]);

    const preguntas = encuestas.length > 0 ? Object.keys(encuestas[0].encuesta) : [];

    const columns = [
        { name: "ID", selector: row => row.id, sortable: true, maxWidth: "100px" },
        ...preguntas.map(pregunta => ({
            name: pregunta,
            selector: row => <div className="w-40 whitespace-normal break-words">{convertirCalificacion(row.encuesta[pregunta]) || "N/A"}</div>,
            sortable: true,
            wrap: true,
            maxWidth: "200px"
        }))
    ];    

    const exportToExcel = () => {
        const formattedData = encuestas.map(item => ({
            ID: item.id,
            ...preguntas.reduce((acc, pregunta) => {
                acc[pregunta] = convertirCalificacion(item.encuesta[pregunta]) || "N/A";
                return acc;
            }, {})
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Encuestas");

        worksheet["!cols"] = [
            { wch: 15 },
            ...preguntas.map(() => ({ wch: 30 }))
        ];

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, "encuestas.xlsx");
    };

    return (
        <div className="overflow-x-auto flex flex-col">
            {user?.superUsuario === false ? 
                <div className="h-screen w-full flex items-center justify-center">
                    <LargeText text="Sin acceso" colorText="text-black" size="text-5xl" textBold />
                </div>
            :
                <div>
                    <ButtonForm onClick={exportToExcel} width="w-36" bgColor="bg-green-600" text="Descargar excel" />  
                    <DataTable
                        title="Control de Calidad"
                        columns={columns}
                        data={encuestas}
                        progressPending={loading}
                        pagination
                        highlightOnHover
                    />
                </div>            
            }
        </div>
    );
    
};

export default ControlDeCalidad;