import React, { useEffect, useState } from "react";
import AlmacenTable from "../adminOrganisms/bar/AlmacenTable";
import { getKitchen } from "../../api";
import { GET_CONSUMABLES } from "../../apiAdmin";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import CreateInsumo from "../adminOrganisms/bar/CreateInsumo";
import H2Custom from "../atoms/H2Custom";
import { useSession } from "../../context/SessionContext";
import LargeText from "../atoms/LargeText";

const Almacenes = () => {
    const [consumables, setConsumables] = useState([]);
    const [cocinas, setCocinas] = useState(null);
    const [searchNombre, setSearchNombre] = useState("");
    const [searchCocina, setSearchCocina] = useState("");
    const { openModal, closeModal, isOpen } = useModal();
    const [loading, setLoading] = useState(false);
    const { user } = useSession();

    const fetchData = async () => {
        try {
            setLoading(true);
            const dataBar = await getKitchen();
            const filteredCocinas = dataBar.filter(cocina => cocina.tipo === "Bar");
            setCocinas(filteredCocinas);
            const data = await GET_CONSUMABLES();
            setConsumables(data);
        } catch (error) {
            console.error("Error al cargar los consumibles", error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.title = "Almacenes - Contenedor";
    }, []); 

    const filteredConsumables = consumables.filter(item => {
        const matchesNombre = item.almacen.nombre.toLowerCase().includes(searchNombre.toLowerCase());
        const matchesCocina = searchCocina ? item.almacen.id_cocina === parseInt(searchCocina) : true;
        return matchesNombre && matchesCocina;
    });

    return(
        <div>
        {user?.superUsuario === false ? 
            <div className="h-screen w-full flex items-center justify-center">
                <LargeText text="Sin acceso" colorText="text-black" size="text-5xl" textBold />
            </div>
        :
            <div>
                <H2Custom text="Almacenes" colorText="" size="text-4x" textBold/>
                <div className="flex gap-4 mb-4 space-x-4">
                    <input 
                        type="text"
                        placeholder="Buscar por nombre"
                        value={searchNombre}
                        onChange={e => setSearchNombre(e.target.value)}
                        className="w-1/3 border-2 bg-transparent text-violet-500 border-gray-200 p-2 pr-8 rounded-xl focus:border-purple-500 focus:outline-none ring-0 shadow-lg focus:shadow-purple-400"
                    />
                    <select
                        value={searchCocina}
                        onChange={e => setSearchCocina(e.target.value)}
                        className="w-1/3 border-2 bg-transparent text-violet-500 border-gray-200 p-2 pr-8 rounded-xl focus:border-purple-500 focus:outline-none ring-0 shadow-lg focus:shadow-purple-400"
                    >
                        <option value="">Todas las cocinas</option>
                        {cocinas?.map(cocina => (
                            <option key={cocina.id} value={cocina.id}>
                                {cocina?.nombre}
                            </option>
                        ))}
                    </select>
                    <ButtonForm text="Crear insumo" bgColor="bg-green-600" width="w-1/3" onClick={openModal}/>
                </div>
                <div className="border-2 border-gray-200 rounded-lg py-2 shadow-lg">
                    <AlmacenTable data={filteredConsumables} cocinas={cocinas} refetch={fetchData} loading={loading} />
                </div>
                {isOpen &&
                    <ModalAdmin title="Crear insumo" onClose={closeModal}>
                        <CreateInsumo refetch={fetchData} onClose={closeModal} cocinas={cocinas} />
                    </ModalAdmin>
                }
            </div>}
        </div>
    )
}

export default Almacenes;
