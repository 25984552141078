import React, { useState } from "react";
import DataTable from "react-data-table-component";
import ButtonForm from "../atoms/ButtonForm";
import { MdDelete } from "react-icons/md";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import CancelProductModal from "./CancelProdcutModal";
import { useSession } from "../../context/SessionContext";
import moment from "moment";
import { toast } from "react-toastify";

const CancelProducts = ({ orden, refetch, onClose }) => {
    const [issue, setIssue] = useState();
    const { user } = useSession();
    const { openModal, isOpen, closeModal } = useModal();
    const clear = () => {
        onClose();
        closeModal();
    }

    const openOrNot = (problem) => {
        if(!moment(orden.createdAt).isSame(moment(), "day") && user?.superUsuario === false){
            toast.error("Solo puedes cancelar ordenes de hoy");
        } else {
            openModal();
            setIssue(problem);
        }
    }

    const columns = [
        {name:"ID", selector: row => row.id},
        {name:"Producto", selector: row => row.nombre},
        {name:"Cantidad", selector: row => row.quantity},
        {name:"Precio", selector: row => `$${row.precio}MX`},
        {name:"Total", selector: row => `$${row.totalProduct}MX`},
        {name:"Acciones", selector: row => 
            <ButtonForm 
                text={<MdDelete size={50} color="white" />} 
                bgColor="bg-red-500" 
                onClick={()=> openOrNot(row) }
            />
        },
    ];

    return(
        <div>
            <DataTable
                data={orden?.orden}
                columns={columns}
            />
            {isOpen &&
                <ModalAdmin onClose={closeModal} title="Cancelar producto" >
                    <CancelProductModal issue={issue} order={orden} onClose={clear} refetch={refetch} />
                </ModalAdmin>
            }
        </div>
    )
}

export default CancelProducts;