import React, { useEffect, useState } from "react";
import { Form, Formik, Field, FieldArray } from "formik";
import DataTable from "react-data-table-component";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import InputFFCaja from "../cajaAtoms/InputFFCaja";
import { getKitchen } from "../../api";
import { GET_WAITERS_ID } from "../../apiAdmin";
import { toast } from "react-toastify";
import { useSession } from "../../context/SessionContext";
import { MdDelete } from "react-icons/md";
import { UPDATE_ORDER } from "../../apiCaja";

const PedidoCaja = ({ orden, onClose, refetch }) => {
    const [mesero, setMesero] = useState();
    const [cocinas, setCocinas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [metodoPago, setMetodoPago] = useState("Efectivo");
    const { user } = useSession();

    const handleTarjetaChange = (tarjeta, valueTotal, setFieldValue) => {
        const tarjetaAmount = parseFloat(tarjeta) || 0;
        const total = parseFloat(valueTotal) || 0;
		const efectivo = total - tarjetaAmount;
        setFieldValue("total_tarjeta", tarjetaAmount);
        setFieldValue("total_efectivo", efectivo > 0 ? efectivo : 0);
	};

    const handleCalcularMixto = (pago_efectivo, total_efectivo, setFieldValue) => {
        const totalPago = parseFloat(pago_efectivo) || 0;
        const totalEfectivo = parseFloat(total_efectivo) || 0;
		const cambio = totalPago - totalEfectivo;
        setFieldValue("pago_efectivo", totalPago);
        setFieldValue("cambio", cambio);
	};

    const handleCalcularCambio = (pago_efectivo, total_efectivo, setFieldValue) => {
        const totalPago = parseFloat(pago_efectivo) || 0;
        const totalEfectivo = parseFloat(total_efectivo) || 0;
		const cambio = pago_efectivo - totalEfectivo;
        setFieldValue("pago_efectivo", totalPago);
        setFieldValue("cambio", cambio);
	};

    useEffect(() => {
        const getInfo = async () => {
            try {
                const meseroData = await GET_WAITERS_ID(orden.id_mesero);
                setMesero(meseroData);
                const cocinasData = await getKitchen();
                setCocinas(cocinasData);
            } catch (err) {
                console.error(err);
            }
        };

        getInfo();
    }, [orden]);

    const agrupadasPorCocinaEstructuradas = Object.entries(orden.orden).map(([id_cocina, productos]) => {
        const cocina = cocinas.find(c => c.id.toString() === id_cocina); 
        return {
            id_cocina,
            nombre: cocina ? cocina.nombre : "Cargando...",
            productos,     
        };
    });

    const columns = [
        { name: "Nombre", selector: row => row.nombre },
        { name: "Total de producto", selector: row => `$${row?.totalProduct} MX` },
        { name: "Cantidad", selector: row => row.quantity },
    ];

    const initialValues = {
        metodo_pago: "Efectivo",
        propina: 0,
        total_general: orden.total_general,
        total_efectivo: null,
        total_tarjeta: null,
        id_caja:user.id,
        status:false,
        comprobantes: [],
        status_caja: "Pagado",
        pago_efectivo:null,
        cambio:null,
        propina:null,
    };
    
    const options = [
        { label: "Efectivo", value: "Efectivo" },
        { label: "Tarjeta", value: "Tarjeta" },
        { label: "Mixto", value: "Mixto" },
    ];
    
    const validate = (values) => {
        const errors = {};
        if (!values.metodo_pago) { errors.metodo_pago = 'Requerido'; }
        if (values.metodo_pago === "Mixto" || values.metodo_pago === "Efectivo") {
            if (Number(values.cambio) < 0 ) { errors.metodo_pago = 'El cambio no puede ser negativo'; }
        }
        if (Number(values.total_efectivo) < 0) { errors.total_efectivo = "No valido, Numero negativo" }
        if (values.propina < 0) { errors.propina = 'No puede ser menor a 0'; }
        if (values.metodo_pago === "Mixto") {
            if (Number(values.total_efectivo) + Number(values.total_tarjeta) !== Number(orden.total_general)) {
                errors.total_mixto = 'La suma de efectivo y tarjeta debe ser igual al total general';
            }
        }
        if (values.metodo_pago === "Mixto") {
            const totalPagoMultiple = values.comprobantes.reduce((sum, pago) => sum + parseFloat(pago.monto || 0), 0);
            if (totalPagoMultiple !== values.total_tarjeta) {
                errors.comprobantes = "La suma de los montos de pago debe ser igual al total del pago con tarjeta";
            }
        }   
        if (values.metodo_pago === "Tarjeta") {
            const totalPagoMultiple = values.comprobantes.reduce((sum, pago) => sum + parseFloat(pago.monto || 0), 0);
            if (totalPagoMultiple !== Number(values.total_general)) {
                errors.comprobantes = "La suma de los montos de pago debe ser igual al total del pago con tarjeta";
            }
        } 
        return errors;
    };

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            if (!values.propina) { values.propina = 0; }
            if (values.metodo_pago === "Efectivo") { values.total_efectivo = values.total_general; values.total_tarjeta = 0; } 
            else if (values.metodo_pago === "Tarjeta") { values.total_tarjeta = values.total_general; values.total_efectivo = 0; }
            await UPDATE_ORDER (orden.id, values);
            toast.success("Pedido liberado correctamente");
            refetch();
            onClose();
        } catch (error) {
            console.error("Error al crear el pedido", error.message || error );
            toast.error("Error al crear el pedido");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full space-y-4">
            {agrupadasPorCocinaEstructuradas.map(({ id_cocina, nombre, productos }) => (
                <div className="border border-black rounded-lg" key={id_cocina}>
                    <LargeText text={`Cocina: ${nombre}`} colorText="" size="text-2xl ml-4" textAlign="text-center" textBold />
                    <DataTable columns={columns} data={productos} />
                </div>
            ))}
            <div className="w-full flex flex-row items-center justify-end space-x-4">
                <LargeText text={`ID: ${orden.id},`} colorText="" size="text-xl" textAlign="text-end" textBold />
                <LargeText text={`Mesa: ${orden.num_mesa},`} colorText="" size="text-xl" textAlign="text-end" textBold />
                <LargeText text={`Mesero: ${mesero?.nombre} ${mesero?.apellido},`} colorText="" size="text-xl" textAlign="text-end" textBold />
                <LargeText text={`Total: $${orden.total_general} MX`} colorText="" size="text-xl" textAlign="text-end" textBold />
            </div>
            <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
                {({ values, setFieldValue, errors }) => (
                    <Form className="w-full">
                        <div className="w-full flex flex-row justify-center items-center space-x-4">
                            <label htmlFor="metodo" className="text-black text-lg font-bold">Metodo de pago</label>
                                <Field
                                    as="select" name="metodo_pago"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMetodoPago(value);
                                        setFieldValue("metodo_pago", value);
                                    }}
                                    className="border-2 bg-transparent text-black border-black w-full p-2 pr-8 rounded-xl focus:border-red-500 focus:outline-none ring-0 disabled:cursor-not-allowed disabled:bg-black disabled:text-yellow-300"
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Field>
                        </div>             
                        {metodoPago === "Mixto" && (
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-row space-x-4">
                                    <InputFFCaja 
                                        name="total_tarjeta" label="Total Tarjeta" value="total_tarjeta" type="number"								
                                        onChange={(e) =>
                                            handleTarjetaChange(e.target.value, values.total_general, setFieldValue)
                                        } 
                                    />
                                    <InputFFCaja 
                                        name="total_efectivo" label="Total Efectivo" value="total_efectivo" type="number" disabled
                                        onChange={(e) => handleTarjetaChange(e.target.value, values.total_general, setFieldValue)} 
                                    />
                                    {errors.total_mixto && <div className="text-red-600">{errors.total_mixto}</div>}
                                </div>
                            </div>
                        )}
                        {metodoPago === "Efectivo" &&
                            <div className="flex flex-row space-x-4 items-center mt-4">
                                <InputFFCaja
                                    name="pago_efectivo" label="Pagó con" value="pago_efectivo" type="number"								
                                    onChange={(e) =>
                                        handleCalcularCambio(e.target.value, values.total_general, setFieldValue)
                                    }
                                    />
                                <InputFFCaja 
                                    name="cambio" label="Cambio" value="cambio" type="number" disabled
                                    onChange={(e) => handleCalcularCambio(e.target.value, values.total_general, setFieldValue)} 
                                />
                                {errors.total_mixto && <div className="text-red-600">{errors.total_mixto}</div>}
                            </div>
                        }
                        {metodoPago === "Mixto" &&
                            <div className="flex flex-row space-x-4 items-center mt-4">
                                <InputFFCaja
                                    name="pago_efectivo" label="Pagó con" value="pago_efectivo" type="number"								
                                    onChange={(e) =>
                                        handleCalcularMixto(e.target.value, values.total_efectivo, setFieldValue)
                                    }
                                />
                                <InputFFCaja 
                                    name="cambio" label="Cambio" value="cambio" type="number" disabled
                                    onChange={(e) => handleCalcularMixto(e.target.value, values.total_general, setFieldValue)} 
                                />
                                {errors.total_mixto && <div className="text-red-600">{errors.total_mixto}</div>}
                            </div>
                        }
                        {metodoPago !== "Efectivo" &&
                            <div className="flex items-center mt-4 w-1/2">
                                <InputFFCaja
                                    name="propina" label="Propina con tarjeta" value="propina" type="number"								
                                />
                            </div>
                        }
                        {(metodoPago === "Mixto" || metodoPago === "Tarjeta" ) && (
						<FieldArray name="comprobantes">
                        {({ push, remove }) => (
                            <div className="mt-4">
                                <LargeText text="Recibos de terminales" size="text-4xl" colorText="" textBold />
                                {values.comprobantes.map((recibo, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row space-x-4 items-center border-violet-300 border p-2 pb-6 mb-2 rounded-lg"
                                    >
                                        <InputFFCaja
                                            name={`comprobantes[${index}].monto`}
                                            label="Monto"
                                            type="number"
                                            placeholder="Monto"
                                            value={recibo.monto}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `comprobantes[${index}].monto`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputFFCaja
                                            name={`comprobantes[${index}].terminal`}
                                            label="No. Terminal"
                                            type="number"
                                            placeholder="No. de terminal"
                                            value={recibo.terminal}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `comprobantes[${index}].terminal`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputFFCaja
                                            name={`comprobantes[${index}].operacion`}
                                            label="No. Operación"
                                            type="number"
                                            placeholder="No. de operacion"
                                            value={recibo.operacion}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `comprobantes[${index}].operacion`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <InputFFCaja
                                            name={`comprobantes[${index}].autorizacion`}
                                            label="Autorización"
                                            type="number"
                                            placeholder="Autorización"
                                            value={recibo.autorizacion}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    `comprobantes[${index}].autorizacion`,
                                                    e.target.value
                                                )
                                            }
                                        />                    
                                        <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="bg-gray-400 hover:bg-red-500 text-white p-2 rounded text-3xl"
                                        >
                                            <MdDelete />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => push({ terminal: "", operacion: "", autorizacion: "", monto: "" })}
                                    className="bg-green-500 text-white p-2 rounded"
                                >
                                    Agregar recibo
                                </button>
                                {errors.comprobantes && typeof errors.comprobantes === "string" && (
                                    <div className="text-red-600 mt-2">{errors.comprobantes}</div>
                                )}
                            </div>
                        )}
                    </FieldArray>                    
                        )}
                        <ButtonForm 
                            width="w-32 ml-auto" type="submit" disabled={loading} 
                            text={loading ? "Liberando" : "Liberar"}  bgColor={loading ? "bg-gray-300" : "bg-green-700"} 
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default PedidoCaja;
