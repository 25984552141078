import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FiAlertTriangle } from "react-icons/fi";
import { MdCompareArrows } from "react-icons/md";
import useModal from "../../Hooks/useModal";
import SetInsumo from "./SetInsumo";
import MoveInsumo from "./MoveInsumo";
import ButtonForm from "../../atoms/ButtonForm";
import ModalAdmin from "../../adminMolecules/ModalAdmin";
import NoData from "../../atoms/NoData";

const TraspasoTable = ({ data, cocinas, refetch, loading }) => {
    const { openModal: openSet , closeModal: closeSet , isOpen: isOpenSet } = useModal();
    const { openModal: openMove , closeModal: closeMove , isOpen: isOpenMove } = useModal();
    const [insumo, setInsumo] = useState();

    const cocinaMap = {};
    cocinas?.forEach(cocina => {
        cocinaMap[cocina.id] = cocina.nombre;
    });

    const handleSet = (row) => {
        setInsumo(row);
        openSet();
    }

    const handleMove = (row) => {
        openMove();
        setInsumo(row);
    }

    const handleClose = () => {
        closeMove();
        closeSet();
        setInsumo(null);
    }

    const columns = [
        { name:"ID", selector: row => row.id, sortable: true, },
        { name:"Nombre", selector: row => row.almacen.nombre, sortable: true, },
        { name:"Bar", selector: row => cocinaMap[row.almacen.id_cocina] || "Sin cocina", },
        { name:"Medida", selector: row => row.medidas,},
        {
            name:"Acciones",
            selector: row => 
                <div className="grid grid-cols-2 space-x-2">
                    <ButtonForm bgColor="bg-orange-500" text={<MdCompareArrows size={30}/>} width="w-12" onClick={()=> handleMove(row)} />
                    <ButtonForm bgColor="bg-yellow-300" text={<FiAlertTriangle size={30} color="black"/>} width="w-12" onClick={()=> handleSet(row)} />
                </div>
        },
    ]

    return(
        <div>
            <DataTable
                data={data} columns={columns} pagination paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50, 100]} progressPending={loading}
                noDataComponent={<NoData/>}
            />
            {isOpenSet &&
                <ModalAdmin title="Crear un ajuste" onClose={handleClose}>
                    <SetInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
            {isOpenMove && 
                <ModalAdmin title="Mover" onClose={handleClose}>
                    <MoveInsumo row={insumo} cocinas={cocinas} refetch={refetch} onClose={handleClose}/>
                </ModalAdmin>
            }
        </div>
    )
}
export default TraspasoTable;