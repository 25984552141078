import { RiAdminFill } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { MdCancelPresentation } from "react-icons/md";
import { PiChefHat, PiCheersFill } from "react-icons/pi";
import { FaRegUserCircle, FaCashRegister } from "react-icons/fa";
import { ImHistory } from "react-icons/im";

export const links = [
    { name:"Cocinas", path:"/TheLaundry/kitchens", icon:PiChefHat, },
    { name:"Pedidos", path:"/TheLaundry/PedidosAdmin", icon:TbReportAnalytics, childs: [], },
    { name:"Caja", path:"/TheLaundry/CajaAdministrador", icon:FaCashRegister, childs: [], },
    { name:"Cancelaciones", path:"/TheLaundry/Cancelaciones", icon:MdCancelPresentation, childs: [], },
    { name:"Historial de Cancelaciones", path:"/TheLaundry/HistorialCancelaciones", icon:ImHistory, childs: [], },
    { name:"Usuarios", path:"/TheLaundry/users", icon:FaRegUserCircle, childs: [], },
    {
        name:"Bar", path:"/TheLaundry/barAdmin", icon:PiCheersFill,
        childs: [
            { name:"Bares", path:"/TheLaundry/barAdmin", },
            { name:"Almacenes", path:"/TheLaundry/almacenes", },
        ],
    },
    { name:"Perfil", path:"/TheLaundry/PerfilAdmin", icon:RiAdminFill, },
]