import React, { useCallback, useEffect, useState } from "react";
import { useSession } from "../../context/SessionContext";
import { GET_ADMIN_ID } from "../../apiAdmin";
import ButtonForm from "../atoms/ButtonForm";
import LargeText from "../atoms/LargeText";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditPerfilAdm from "../adminOrganisms/EditPerfilAdm";

const PerfilAdminTL = () => {
    const { user } = useSession();
    const [admin, setAdmin] = useState();
    const { openModal, isOpen, closeModal } = useModal();
    
    const fetchAdmin = useCallback(async () => {
        try {
            const adminData = await GET_ADMIN_ID(user?.id);
            setAdmin(adminData);
        } catch (error) {
            console.error(error);
        }
    }, [user?.id]);

    useEffect(()=> {
        fetchAdmin();
    }, [fetchAdmin]);

    return(
        <div className="h-[50vh] mt-24 p-6 flex flex-col justify-center items-center bg-green-200 rounded-lg shadow-lg">
            <LargeText text="Editar" colorText="text-violet-600" textBold size="text-4xl" />
            <LargeText text="Nombre:" colorText="text-black" size="text-2xl" />
            <LargeText text={admin?.nombre} colorText="text-black" textBold size="text-3xl" />
            <LargeText text="Apellido:"colorText="text-black" size="text-2xl" />
            <LargeText text={admin?.apellido} colorText="text-black" textBold size="text-3xl" />
            <LargeText text="Nombre de usuario:" colorText="text-black" size="text-2xl" />
            <LargeText text={admin?.user?.username} colorText="text-black" textBold size="text-3xl" />
            <ButtonForm text="Editar" bgColor="bg-orange-500" width="w-full sm:w-1/4" onClick={openModal} />
            {isOpen && 
                <ModalAdmin title="Editar perfil" onClose={closeModal} >
                    <EditPerfilAdm onClose={closeModal} refetch={fetchAdmin} admin={admin} />
                </ModalAdmin>
            }
        </div>
    );
}

export default PerfilAdminTL;