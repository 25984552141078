import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import useModal from "../Hooks/useModal";
import NoData from "../atoms/NoData";
import H2Custom from "../atoms/H2Custom";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import PedidoHistorial from "../CajaOrganism/PedidoHistorial";
import { getKitchen } from "../../api";
import { GET_WAITERS, GET_ORDERS_BY_DATE } from "../../apiAdmin"; 

const PedidosAdmin = () => {
	const [row, Setrow] = useState(null);
	const [orden, setOrden] = useState(null);
	const [loading, setLoading] = useState(false);
	const [historial, setHistorial] = useState([]);
	const [cocinas, setCocinas] = useState();
	const [dataWaiters, setDataWaiters] = useState([]);
	const { openModal, isOpen, closeModal } = useModal();
	const [endDate, setEndDate] = useState(moment().endOf("day").toISOString());
	const [initialDate, setInitialDate] = useState(moment().startOf("day").toISOString());
	const [totales, setTotales] = useState({
		totalEfectivo: 0,
		totalTarjeta: 0,
		ventaTotal: 0,
	});

	const getHistorial = async () => {
		try {
			setLoading(true);
			const historialData = await GET_ORDERS_BY_DATE(initialDate, endDate); 
			const waitersData = await GET_WAITERS();
			setHistorial(historialData);
			setDataWaiters(waitersData);

			const totalEfectivo = historialData.reduce((acc, pedido) => acc + (Number(pedido.total_efectivo) || 0), 0);
			const totalTarjeta = historialData.reduce((acc, pedido) => acc + (Number(pedido.total_tarjeta) || 0), 0);
			const ventaTotal = historialData.reduce((acc, pedido) => acc + (Number(pedido.total_general) || 0), 0);

			setTotales({ totalEfectivo, totalTarjeta, ventaTotal });
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const fetchCocinas = async () => {
			const kitchensData = await getKitchen();
			setCocinas(kitchensData);
		};
	
		fetchCocinas();
	}, []);
	
	useEffect(() => {
		getHistorial();
	}, [initialDate, endDate]);

	useEffect(() => {
		document.title = "Historial de pedidos - Contenedor";
	}, []);

	const handleOpen = (orden, row) => {
		setOrden(orden);
		Setrow(row);
		openModal();
	};

	const handleClose = () => {
		setOrden(null);
		Setrow(null);
		closeModal();
	};

	const getWaiterName = (id_mesero) => {
		const waiter = dataWaiters?.find((waiter) => waiter.id === id_mesero);
		return waiter ? waiter.nombre : "Desconocido";
	};

	const getKitchenName = (id_cocina) => {
		if (!cocinas || cocinas.length === 0) return "Desconocido";  
		const kitchen = cocinas.find((cocina) => (cocina.id) === Number(id_cocina));
		return kitchen ? kitchen.nombre : "Desconocido";
	};	

	const handleFilter = () => {
		getHistorial();
	};

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "Metodo de pago",
			selector: (row) => row.metodo_pago || "Cuenta abierta",
			sortable: true,
		},
		{
			name: "Total",
			selector: (row) => `$${row.total_general} MX`,
			sortable: true,
		},
		{
			name: "Efectivo",
			selector: (row) => `$${row.total_efectivo || 0} MX`,
			sortable: true,
		},
		{
			name: "Tarjeta",
			selector: (row) => `$${row.total_tarjeta || 0} MX`,
			sortable: true,
		},
		{
			name: "Mesa",
			selector: (row) => row.num_mesa,
			sortable: true,
		},
		{
			name: "Mesero",
			selector: (row) => getWaiterName(row.id_mesero),
			sortable: true,
		},
		{
			name: "Fecha",
			selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
			sortable: true,
		},
		{
			name: "Acciones",
			selector: (row) => (
				<div>
					<ButtonForm
						text="Ver"
						bgColor="bg-green-600"
						width="w-16"
						onClick={() => handleOpen(row.orden, row)}
					/>
				</div>
			),
		},
	];

	const columns2 = [
		{
			name: "Nombre",
			selector: (row) => getKitchenName(row.id),
			sortable: true,
		},
		{
			name: "Ganancia cocina",
			selector: (row) => row.gananciaCocina,
			sortable: true,
		},
		{
			name: "Ganancia contenedor",
			selector: (row) => row.gananciaContenedor,
			sortable: true,
		},
		{
			name: "Ganancia total",
			selector: (row) => row.gananciaTotal,
			sortable: true,
		},
	]

	const calculateTotalProducts = (orden) => {
        return orden.reduce((total, item) => total + item.totalProduct, 0);
    };

    const calculateGananciaCocina = (orden) => {
        return orden.reduce((total, item) => total + (item.precio_compra * item.quantity), 0);
    };

	const calculateGananciaBar = (orden) => {
        return orden.reduce((total, item) => total + (item.precio * item.quantity), 0);
    };

	const calculateGananciasTotales = () => {
		const gananciasPorCocina = {};
	
		historial.forEach((row) => {
			if (!row.orden) return;
	
			cocinas.forEach((cocina) => {
				const cocinaId = cocina.id;
				const orden = row.orden[cocinaId] || [];
	
				const totalGeneral = calculateTotalProducts(orden);
				let gananciaCocina = 0;
				
				if (cocina.tipo === "Cocina") {
					gananciaCocina = calculateGananciaCocina(orden);
				} else if (cocina.tipo === "Bar") {
					gananciaCocina = totalGeneral * 0.42;
				}

				const gananciaContenedor = totalGeneral - gananciaCocina;
	
				if (!gananciasPorCocina[cocinaId]) {
					gananciasPorCocina[cocinaId] = {
						gananciaCocina: 0,
						gananciaContenedor: 0,
						gananciaTotal: 0,
					};
				}
	
				gananciasPorCocina[cocinaId].gananciaCocina += gananciaCocina;
				gananciasPorCocina[cocinaId].gananciaContenedor += gananciaContenedor;
				gananciasPorCocina[cocinaId].gananciaTotal += gananciaCocina + gananciaContenedor;
			});
		});
	
		return gananciasPorCocina;
	};
	
	const gananciasPorCocina = calculateGananciasTotales();	

	const exportToExcel = () => {
		const pedidosData = historial.map((row) => ({
			ID: row.id,
			"Método de pago": row.metodo_pago || "Cuenta abierta",
			Total: Number(row.total_general),
			Efectivo: Number(row.total_efectivo) || 0,
			Tarjeta: Number(row.total_tarjeta) || 0,
			Mesa: row.num_mesa,
			Mesero: getWaiterName(row.id_mesero),
			Fecha: moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
		}));

		const cocinasData = Object.keys(gananciasPorCocina).map((cocinaId) => ({
			ID: getKitchenName(cocinaId),
			"Ganancia cocina": gananciasPorCocina[cocinaId].gananciaCocina,
			"Ganancia contenedor": gananciasPorCocina[cocinaId].gananciaContenedor,
			"Ganancia total": gananciasPorCocina[cocinaId].gananciaTotal,
		}));

		const wb = XLSX.utils.book_new();
		const wsPedidos = XLSX.utils.json_to_sheet(pedidosData);
		const wsCocinas = XLSX.utils.json_to_sheet(cocinasData);

		XLSX.utils.book_append_sheet(wb, wsPedidos, "Pedidos");
		XLSX.utils.book_append_sheet(wb, wsCocinas, "Cocinas");

		if (cocinas) {
			cocinas.forEach((cocina) => {
				const pedidosCocina = historial
					.filter((row) => row.orden && row.orden[cocina.id])
					.map((row) => (
					{
						ID: row.id,
						"Método de pago": row.metodo_pago || "Cuenta abierta",
						"Ganancia Cocina": cocina?.tipo === "Cocina" ? 
							calculateGananciaCocina(row?.orden[cocina?.id]) : 
							calculateGananciaBar(row?.orden[cocina?.id]) 
							|| 
							0,
						"Ganancia Contenedor": cocina?.tipo === "Cocina" ? 
						calculateTotalProducts(row?.orden[cocina?.id]) - calculateGananciaCocina(row?.orden[cocina?.id]) : 
						calculateTotalProducts(row?.orden[cocina?.id]) - calculateGananciaBar(row?.orden[cocina?.id]) 
						|| 
						0,
						"Ganancia Total": calculateTotalProducts(row?.orden[cocina?.id]) || 0,
						Mesa: row.num_mesa,
						Mesero: getWaiterName(row.id_mesero),
						Fecha: moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
					}));
	
				if (pedidosCocina.length > 0) {
					const wsCocina = XLSX.utils.json_to_sheet(pedidosCocina);
					XLSX.utils.book_append_sheet(wb, wsCocina, cocina.nombre);
				}
			});
		}
	
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const excelFile = new Blob([excelBuffer], { type: "application/octet-stream" });
	
		saveAs(
			excelFile,
			`pedidosYGanancias${moment(initialDate).format("DD-MM-YY")}A${moment(endDate).format("DD-MM-YY")}.xlsx`
		);
	};
	
	return (
		<div>
			<div className="w-full border-2 border-gray-200 rounded-lg py-2 overflow-x-auto">
				<div className="flex flex-row justify-center items-center space-x-2 md:space-x-4 px-2">
					<H2Custom
						text="Historial de pedidos"
						textAling="text-center"
						size="text-xl md:text-2xl"
						colorText=""
						textBold
					/>
					<input
						type="date"
						value={moment(initialDate).format("YYYY-MM-DD")}
						onChange={(e) =>
							setInitialDate(moment(e.target.value).startOf("day").toISOString())
						}
						className="border-[1px] border-black p-2 rounded-full bg-white "
					/>
					<input
						type="date"
						value={moment(endDate).format("YYYY-MM-DD")}
						onChange={(e) =>
							setEndDate(moment(e.target.value).endOf("day").toISOString())
						}
						className="border-[1px] border-black p-2 rounded-full bg-white "
					/>
					<ButtonForm
						text="Filtrar"
						onClick={handleFilter}
						width="w-24"
						bgColor="border border-black hover:border-0 hover:bg-yellow-300"
						textColor="text-black"
					/>
					<ButtonForm
						text="Actualizar"
						onClick={getHistorial}
						width="w-24"
						bgColor="border border-black hover:border-0 hover:bg-yellow-300"
						textColor="text-black"
					/>
					<ButtonForm
						text="Exportar pedidos"
						onClick={exportToExcel}
						width="w-24"
						bgColor="bg-green-700"
						textColor="text-white"
					/>
				</div>
				<DataTable
					data={Object.keys(gananciasPorCocina).map((cocinaId) => ({
						id: cocinaId,
						...gananciasPorCocina[cocinaId]
					}))}
					columns={columns2}
				/>
				<div className="mt-4 text-center">
					<p>Total en efectivo: <strong>${(totales?.totalEfectivo) || 0} MX</strong></p>
					<p>Total en tarjeta: <strong>${(totales?.totalTarjeta) || 0} MX</strong></p>
					<p>Venta total: <strong>${(totales?.ventaTotal) || 0} MX</strong></p>
				</div>
				<DataTable
					data={historial}
					columns={columns}
					pagination
					progressPending={loading}
					paginationPerPage={10}
					paginationRowsPerPageOptions={[10, 20, 50, 100]}
					noDataComponent={<NoData />}
				/>
			</div>
			{isOpen && orden !== undefined && row !== null && (
				<ModalAdmin title="Pedido" onClose={handleClose}>
					<PedidoHistorial ordenes={orden} row={row} />
				</ModalAdmin>
			)}
		</div>
	);
};

export default PedidosAdmin;
