import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { useSession } from "../../context/SessionContext";
import { getKitchenById } from "../../api";
import { CREATE_CANCELLATION, DELETE_ORDER, UPDATE_ORDER } from "../../apiAdmin";
import { GET_ADMIN_ID, GET_WAITERS } from "../../apiAdmin";

const CancelProductModal = ({ issue, onClose, order, refetch }) => {
    const [loading, setLoading] = useState(false);
    const [meseros, setMeseros] = useState();
    const [admin, setAdmin] = useState();
    const [cocina, setCocina] = useState();
    const { user } = useSession();

    const fetchOrders = async () => {
        try {
            setLoading(true);
            const meserosData = await GET_WAITERS();
            setMeseros(meserosData);
            const cocinaData = await getKitchenById(order?.id_cocina);
            setCocina(cocinaData);
            const adminData = await GET_ADMIN_ID(user?.id);
            setAdmin(adminData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const initialValues = { password: '', razon: '', }

    const handleSubmit = async (values) => {
        try {
            setLoading(true);

            await CREATE_CANCELLATION({
                ...values,
                id_admin: Number(admin?.id),
                id_mesero: Number(order?.id_mesero),
                id_cocina: Number(cocina?.id),
                producto: issue.nombre,
                precio_compra: Math.round(Number(issue.precio_compra)),
                precio_lista: Math.round(Number(issue.precio_lista)),
                precio: Math.round(Number(issue.precio)),                
            });
            const updatedOrder = order.orden.filter(
                (producto) => producto.uniqueId !== issue.uniqueId
            );

            if (updatedOrder.length === 0) {
                await DELETE_ORDER(order.id);
            } else {
                await UPDATE_ORDER(order.id, 
                    { orden: updatedOrder, total: order.total - issue.totalProduct } 
                );
            }
            toast.success("Pedido cancelado correctamente");
            refetch();
            onClose();
        } catch (error) {
            if (error.response?.status === 401) {
                toast.error("Contraseña incorrecta. Por favor, inténtalo de nuevo.");
            } else {
                console.error("Error al cancelar", error);
                toast.error("Error al cancelar el pedido.");
            }
        } finally {
            setLoading(false);
        }
    };

    const getWaiterName = (idMesero) => {
        const waiter = meseros?.find((mesero) => mesero.id === idMesero);
        return waiter ? waiter?.user.username : "Desconocido";
    };

    const validate = (values) => {
        const errors = {};
        if (!values.razon) { errors.razon = "Campo requerido"; }  
        if (!values.password) { errors.password = "Campo requerido"; }
        return errors;
    };

    return(
        <div>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-row justify-between">
                    <LargeText text={`Orden: ${order.id}`} colorText="text-black" textBold/>
                    <LargeText text={`Mesero: ${getWaiterName(order.id_mesero)}`} colorText="text-black" textBold/>
                </div>
                <div className="flex flex-row justify-between">
                    <LargeText text={`Cocina: ${cocina?.nombre}`} colorText="text-black" textBold/>
                    <LargeText text={`Platillo: ${issue.nombre}`} colorText="text-black" textBold/>
                    <LargeText text={`Precio: $${issue.precio}MX`} colorText="text-black" textBold/>
                </div>
            </div>
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validate={validate} >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="">
                        <div className="w-full flex flex-row space-x-4">
                            <InputFFAdmin name="razon" label="Razón" />
                            <InputFFAdmin name="password" label="Contraseña" type="password" />
                        </div>
                        <ButtonForm
                            width="w-64 mt-6 ml-auto" type="submit" 
                            bgColor={loading ? "bg-gray-300" :"bg-purple-300"} 
                            text={loading ? "Cargando..." :"Cancelar producto"} disabled= {loading ? true : false}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default CancelProductModal;