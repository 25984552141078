import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GET_ORDERS, GET_WAITERS } from "../../apiTheLaundry/apiAdmin";
import LargeText from "../atoms/LargeText";
import H2Custom from "../atoms/H2Custom";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import { getKitchen } from "../../apiTheLaundry/api";
import CancelProducts from "../adminOrganisms/CancelProducts";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import moment from "moment";

const CancelacionesTH = () => {
    const [groupedOrders, setGroupedOrders] = useState({});
    const [meseros, setMeseros] = useState();
    const [cocinas, setCocinas] = useState();
    const [openTable, setOpenTable] = useState(null);
    const { openModal, isOpen, closeModal } = useModal();
    const [orden, setOrden] = useState();

    const fetchOrders = async () => {
        try {
            const ordersData = await GET_ORDERS();
            const grouped = ordersData.reduce((acc, order) => {
                if (!acc[order.num_mesa]) {
                    acc[order.num_mesa] = [];
                }
                acc[order.num_mesa].push(order);
                return acc;
            }, {});
            setGroupedOrders(grouped);
            const meserosData = await GET_WAITERS();
            setMeseros(meserosData);
            const cocinasData = await getKitchen();
            setCocinas(cocinasData);
        } catch (error) {
            console.error(error);
        }
    };

    const toggleTable = (numMesa) => {
        setOpenTable(openTable === numMesa ? null : numMesa);
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const getWaiterName = (idMesero) => {
        const waiter = meseros?.find((mesero) => mesero.id === idMesero);
        return waiter ? waiter?.user.username : "Desconocido";
    };
    
    const getKitchenName = (idCocina) => {
        const kitchen = cocinas?.find((cocina) => cocina.id === idCocina);
        return kitchen ? kitchen.nombre : "Desconocido";
    };

    const viewProducts = (selected) => {
        setOrden(selected);
        openModal();
    }

    const clear = () => {
        closeModal();
        setOrden(null);
    }
    
    const columns = [
        { name: "ID", selector: (row) => row.id },
        { name: "Estatus", selector: (row) => row.status },
        { name: "Mesero", selector: (row) => getWaiterName(row.id_mesero) },
        { name: "Cocina", selector: (row) => getKitchenName(row.id_cocina) },
        { name: "Total", selector: (row) => `$${row.total}MX` },
        { name: "Fecha", selector: (row) => moment(row.createdAt).format("DD/MM/YY HH:mm") },
        { name:"Acciones", selector: (row) => 
            <ButtonForm text="Ver productos" onClick={()=>viewProducts(row)} bgColor="bg-orange-500" width="w-32" /> 
        }
    ];
    
    return (
        <div className="space-y-4">
            <H2Custom text="Mesas activas" colorText="text-black" size="text-4xl" textBold />
            {Object.keys(groupedOrders).map((numMesa) => (
                <div key={numMesa} className="bg-red-300 rounded-lg p-4" >
                    <div className="w-full cursor-pointer" onClick={() => toggleTable(numMesa)}>
                        <LargeText text={`Mesa ${numMesa}`} textBold colorText="text-black" />
                    </div>
                    {openTable === numMesa && (
                        <DataTable
                            data={groupedOrders[numMesa]}
                            columns={columns}
                        />
                    )}
                </div>
            ))}
            {isOpen && orden &&
                <ModalAdmin onClose={clear} title="Cancelar productos" >
                    <CancelProducts orden={orden} refetch={fetchOrders} onClose={clear} />
                </ModalAdmin>
            }
        </div>
    );
};

export default CancelacionesTH;
