import React, { useState, useEffect } from "react";
import { getKitchen } from "../../api";
import { GET_ADMINS, GET_CANCELLATIONS, GET_WAITERS } from "../../apiAdmin";
import ButtonForm from "../atoms/ButtonForm";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";

const HistorialDeCancelaciones = () => {
    const [cocinas, setCocinas] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [meseros, setMeseros] = useState([]);
    const [selectedKitchen, setSelectedKitchen] = useState("");
    const [selectedWaiter, setSelectedWaiter] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const [cancellations, setCancellations] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setAdmins(await GET_ADMINS());
                setCocinas(await getKitchen());
                setMeseros(await GET_WAITERS());
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCancellations = async () => {
            try {
                setCancellations(await GET_CANCELLATIONS());
            } catch (error) {
                console.error(error);
            }
        };
        fetchCancellations();
    }, []);

    const getWaiterName = (idMesero) => {
        const waiter = meseros.find((mesero) => mesero.id === idMesero);
        return waiter ? waiter?.user.username : "Desconocido";
    };

    const getKitchenName = (idCocina) => {
        const kitchen = cocinas.find((cocina) => cocina.id === idCocina);
        return kitchen ? kitchen.nombre : "Desconocido";
    };

    const getAdminName = (idAdmin) => {
        const admin = admins.find((admin) => admin.id === idAdmin);
        return admin ? admin.nombre : "Desconocido";
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(
            filteredCancellations.map((cancellation) => ({
                ID: cancellation.id,
                Razon: cancellation.razon,
                Producto: cancellation.producto,
                Precio: `$${cancellation.precio} MX`,
                Cocina: getKitchenName(cancellation.id_cocina),
                Admin: getAdminName(cancellation.id_admin),
                Mesero: getWaiterName(cancellation.id_mesero),
                Fecha: moment(cancellation.createdAt).format("DD/MM/YY HH:mm"),
            }))
        );

        const wscols = [
            { wch: 10 }, 
            { wch: 60 },
            { wch: 60 }, 
            { wch: 15 }, 
            { wch: 20 }, 
            { wch: 20 }, 
            { wch: 20 }, 
            { wch: 18 }, 
        ];
        ws["!cols"] = wscols;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Cancelaciones");
        
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, `historial_cancelaciones_${selectedStartDate ==! "" ? moment(selectedStartDate).format("DD-MM-YY") : "historial" }_${selectedEndDate ==! "" ? moment(selectedEndDate).format("DD-MM-YY") : ""}.xlsx`);
    };

    const columns = [
        { name: "ID", selector: (row) => row.id },
        { name: "Razon", selector: (row) => row.razon },
        { name: "Producto", selector: (row) => row.producto },
        { name: "Precio", selector: (row) => `$${row.precio}` },
        { name: "Cocina", selector: (row) => getKitchenName(row.id_cocina) },
        { name: "Involucrados", selector: (row) => 
            <div className="flex flex-col">
                <div>Autorizó: {getAdminName(row.id_admin)}</div> 
                <div>Mesero: {getWaiterName(row.id_mesero)}</div>
            </div> 
        },
        { name: "Fecha", selector: (row) => moment(row.createdAt).format("DD/MM/YY HH:mm") },
    ];

    const filteredCancellations = cancellations.filter((cancellation) => {
        const matchesKitchen = selectedKitchen ? cancellation.id_cocina === parseInt(selectedKitchen) : true;
        const matchesWaiter = selectedWaiter ? cancellation.id_mesero === parseInt(selectedWaiter) : true;

        const createdAt = moment(cancellation.createdAt);
        const matchesDate =
            (selectedStartDate ? createdAt.isSameOrAfter(moment(selectedStartDate, "YYYY-MM-DD")) : true) &&
            (selectedEndDate ? createdAt.isSameOrBefore(moment(selectedEndDate, "YYYY-MM-DD").endOf("day")) : true);

        return matchesKitchen && matchesWaiter && matchesDate;
    });

    return (
        <div className="border-2 border-black p-4 rounded-lg shadow-lg">
            <div className="mb-4 flex flex-wrap justify-between gap-4">
                <div className="flex flex-col">
                    <label htmlFor="kitchenFilter" className="mr-2 font-bold">Cocina:</label>
                    <select
                        id="kitchenFilter"
                        className="border rounded py-2 px-4 border-violet-300"
                        value={selectedKitchen}
                        onChange={(e) => setSelectedKitchen(e.target.value)}
                    >
                        <option value="">Todas las cocinas</option>
                        {cocinas.map((cocina) => (
                            <option key={cocina.id} value={cocina.id}>
                                {cocina.nombre}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="waiterFilter" className="mr-2 font-bold">Mesero:</label>
                    <select
                        id="waiterFilter"
                        className="border rounded py-2 px-4 border-violet-300"
                        value={selectedWaiter}
                        onChange={(e) => setSelectedWaiter(e.target.value)}
                    >
                        <option value="">Todos los meseros</option>
                        {meseros.map((mesero) => (
                            <option key={mesero.id} value={mesero.id}>
                                {mesero.user.username}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="startDate" className="mr-2 font-bold">De:</label>
                    <input
                        type="date"
                        id="startDate"
                        className="border rounded py-2 px-4 bg-white border-violet-300"
                        value={selectedStartDate}
                        onChange={(e) => setSelectedStartDate(e.target.value)}
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="endDate" className="mr-2 font-bold">A:</label>
                    <input
                        type="date"
                        id="endDate"
                        className="border rounded py-2 px-4 bg-white border-violet-300"
                        value={selectedEndDate}
                        onChange={(e) => setSelectedEndDate(e.target.value)}
                    />
                </div>
                <ButtonForm
                    onClick={exportToExcel}
                    bgColor="bg-green-700"
                    text="Exportar"
                    width="w-32"
                />
            </div>
            <DataTable data={filteredCancellations} columns={columns} />
        </div>
    );
};

export default HistorialDeCancelaciones;