import React from "react";
import { useSession } from "../../context/SessionContext";
import ButtonForm from "../atoms/ButtonForm";
import LargeText from "../atoms/LargeText";
import useModal from "../Hooks/useModal";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import EditPerfilAdm from "../adminOrganisms/EditPerfilAdm";

const PerfilAdmin = () => {
    const { user } = useSession();
    const { openModal, isOpen, closeModal } = useModal();
    
    return(
        <div className="h-[50vh] mt-24 p-6 flex flex-col justify-center items-center bg-green-200 rounded-lg shadow-lg">
            <LargeText text="Editar" colorText="text-violet-600" textBold size="text-4xl" />
            <LargeText text="Nombre:" colorText="text-black" size="text-2xl" />
            <LargeText text={user?.nombre} colorText="text-black" textBold size="text-3xl" />
            <LargeText text="Apellido:"colorText="text-black" size="text-2xl" />
            <LargeText text={user?.apellido} colorText="text-black" textBold size="text-3xl" />
            <LargeText text="Nombre de usuario:" colorText="text-black" size="text-2xl" />
            <LargeText text={user?.user?.username} colorText="text-black" textBold size="text-3xl" />
            <ButtonForm text="Editar" bgColor="bg-orange-500" width="w-full sm:w-1/4" onClick={openModal} />
            {isOpen && 
                <ModalAdmin title="Editar perfil" onClose={closeModal} >
                    <EditPerfilAdm onClose={closeModal} admin={user} />
                </ModalAdmin>
            }
        </div>
    );
}

export default PerfilAdmin;